/* eslint-disable import/prefer-default-export */
export const heroSuccess = {
  title: "success stories",
  subTitle: "Our merchant",
};

export const heroQR = {
  title: "SpotOn QR",
  subTitle: "Success stories.",
};

export const heroSlides = [
  {
    image: "Kays_place.jpg",
    companyName: "Kay’s Place",
    companyIs: "Casual dining restaurant",
    companyLogo: "Casual Dining logo-1.png",
    address: "Kingsville, OH",
    owner: "Kimberly Thomas",
    website: "https://kaysplacerestaurant.com/",
    description:
      "Kimberly Thomas’s old POS had problems with connectivity, back-of-house communications, and disappearing orders. By implementing online ordering with SpotOn, she was able to save time, serve customers more efficiently, and adhere to social distancing guidelines.",
    url: "",
    ctaPrimary: "Read The Story",
    info: [
      {
        numbers: "95%",
        numberType: "increase in efficiency and accuracy",
      },
      {
        numbers: "50%",
        numberType: "Major reduction in kitchen errors",
      },
    ],
  },
];

export const vtSlides = [
  {
    image: "pack_and_ship.jpg",
    companyName: "Pack & Ship Plus",
    companyIs: "Business services store",
    companyLogo: "Payments logo-1.png",
    address: " Colorado Springs, CO",
    owner: "Noelle Wright",
    website: "https://www.packandshipplus.biz/",
    description:
      "Noelle Wright’s business was stuck in a contract with a processing company that charged high fees and required an equipment lease. With SpotOn Terminal, Pack & Ship Plus gained a full-service solution with low-cost, transparent credit card processing.",
    url: "",
    ctaPrimary: "Read The Story",
    info: [
      {
        numbers: "$140K",
        numberType: "annual card sales",
      },
      {
        numbers: "$840",
        numberType: "annual savings",
      },
    ],
  },
];

export const autoBTSlides = [
  {
    image: "Automotive-1.png",
    companyName: "DIY Garage Knox",
    companyIs: "Auto garage",
    companyLogo: "Automotive logo-1.png",
    address: "Knoxville, TN",
    owner: "B.K. Hardin",
    website: "https://www.diygarageknox.com/",
    description:
      "B.K. Hardin’s “DIY” garage struggled without a software solution to meet his needs. With SpotOn Appointments, bookings have increased. SpotOn Website attracts new customers, and SpotOn payments provides low-cost, transparent credit card processing.",
    url: "",
    ctaPrimary: "Read The Story",
    info: [
      {
        numbers: "$35,000/year",
        numberType: "in new business generated",
      },
      {
        numbers: "20+",
        numberType: "appointments per week",
      },
    ],
  },
];
export const NorthEastAutoSlides = [
  {
    image: "northeast-auto-prime.png",
    companyName: "Northeast Auto Imports",
    companyIs: "Car dealership",
    companyLogo: "northeast-auto-logo.png",
    address: "Hudson, New Hampshire",
    owner: "Derek Griffith",
    website: "www.northeastautoimports.com",
    logoStyles: { width: "61px", height: "61px", margin: "0 auto" },
    description:
      "As a dealership owner and professional race car driver, Derek Griffith leverages SpotOn's automotive business solutions to streamline test-driving appointments, grow his customer base, and manage his entire operation remotely—even when he's at the track.",
    url: "https://spoton.com/blog/derek-griffith-driving-business-growth/",
    ctaTitle: "Read The Story",
    info: [
      {
        numbers: "Remote \n Access",
        numberType: "to his business from the SpotOn app",
        isAuto: true,
        customPara: "mt-[80px]",
      },
      {
        numbers: "24/7/365",
        numberType: "personalized customer support",
        customPara: "mt-[80px]",
      },
    ],
  },
];
export const autoCsSlides = [
  {
    image: "Automotive-1.png",
    companyName: "Northeast Auto Imports",
    companyIs: "Auto garage",
    companyLogo: "NE-auto-imports.png",
    address: "Hudson, New Hampshire",
    owner: "Derek Griffith",
    website: "northeastautoimports.com",
    description:
      "With SpotOn, Derek Griffith has improved efficiency at his auto import business with online appointment booking and improved his online reputation to attract more customers—even when he’s on the road as a professional race driver.",
    url: "",
    ctaPrimary: "Read The Story",
    info: [
      {
        numbers: "3+ weeks",
        numberType: "Test drives booked in advance",
      },
      {
        numbers: "5 star",
        numberType: "Online review reputation",
      },
    ],
  },
];

export const restBTSlides = [
  {
    image: "Restaurant Overview Page-1.png",
    companyName: "Duggan’s Irish Pub",
    companyIs: "Bar & restaurant",
    companyLogo: "Restaurant Overview Page logo.png",
    address: "Royal Oak, MI",
    owner: "Tony Kasab",
    website: "https://www.dugganspub.com/",
    description:
      "Keeping up with busy crowds requires a restaurant POS software that can move fast. With SpotOn Restaurant, owner Tony Kasab can make menu changes in real-time, and his staff can quickly run orders and payments without worrying about mistakes thanks to the custom menu prompts.",
    url: "",
    ctaPrimary: "Read The Story",
    info: [
      {
        numbers: "100+",
        numberType: "employee POS users",
      },
      {
        numbers: "160",
        numberType: "checks per day",
      },
    ],
  },
];

export const hbBTSlides = [
  {
    image: "Appointments product page.png",
    companyName: "Pieces By Vaughn",
    companyIs: "Hair Salon",
    companyLogo: "Appointments product page logo-1.png",
    address: "Murfreesboro, TN",
    owner: "Kiwaski Vaughn",
    website: "http://piecesbyvaughn.com/",
    description:
      "Kiwaski Vaughn needed a solution to attract and retain clientele. By implementing SpotOn Appointments for booking and scheduling, SpotOn Payments, and SpotOn Marketing and Loyalty to attract new customers and incentivize repeat visits, his business is thriving.",
    url: "",
    ctaPrimary: "Read The Story",
    info: [
      {
        numbers: "100+",
        numberType: "Loyalty check-ins every month",
      },
      {
        numbers: "50%",
        numberType: "of clients visiting on a monthly basis",
      },
    ],
  },
];

export const temakiSlides = [
  {
    image: "Temaki Den 2.png",
    companyName: "Temaki Den",
    companyIs: "Restaurant",
    companyLogo: "Delivery logistics logo-1.png",
    address: "Denver, CO",
    owner: "Kenta Kamo",
    website: "https://www.temakiden.com/",
    description:
      "Launching during the pandemic, Temaki Den faced a unique challenge, but SpotOn made it easy to offer online ordering so their guests can choose between pickup and delivery right from their website, with orders going directly to the restaurant point-of-sale.",
    url: "",
    ctaPrimary: "Read The Story",
    info: [
      {
        numbers: "100%",
        numberType: "of sales through online ordering",
      },
      {
        numbers: "20%",
        numberType: "Savings on each order",
      },
    ],
  },
];

export const minuteManPressSlides = [
  {
    image: "Minuteman Press 1.jpg",
    companyName: "Minuteman Press",
    companyIs: "Printing business",
    companyLogo: "Professional Services logo-1.png",
    address: "San Diego, CA",
    owner: "Marcus Clemente",
    website: "https://www.minutemanpress.com/",
    description:
      "With 40% of his business being online, Marcus Clemente needed a user-friendly solution for invoicing. With SpotOn Virtual Terminal, he gained the ability to get paid quickly and easily, before printing was completed, while also growing his marketing reach.",
    url: "",
    ctaPrimary: "Read The Story",
    info: [
      {
        numbers: "100%",
        numberType: "of sales through online ordering",
      },
      {
        numbers: "20%",
        numberType: "Savings on each order",
      },
    ],
  },
];

export const blueCrabSlides = [
  {
    image: "Blue Crab Shack 1.jpg",
    companyName: "Blue Crab Shack - The ‘Ville",
    companyIs: "Restaurant",
    companyLogo: "Online Ordering logo-1.png",
    address: "Madison, TN",
    owner: "Lisa Walker",
    website: "https://bluecrab-shack.com/ ",
    description:
      "Lisa Walker and her brother Brian were losing money due to their POS system. SpotOn provided a POS solution including full-service online ordering for pickup/delivery and prepay, contactless dine-in ordering, and POS hardware for quick and easy payments.",
    url: "",
    ctaPrimary: "Read The Story",
    info: [
      {
        numbers: "100%",
        numberType: "of sales through online ordering",
      },
      {
        numbers: "20%",
        numberType: "Savings on each order",
      },
    ],
  },
];

export const argantiSlides = [
  {
    image: "Argenti-main.png",
    companyName: "Argenti Designer Jewelers",
    companyIs: "Fine jewelry",
    companyLogo: "argeenti_logo.png",
    address: "Lauderdale by the Sea",
    owner: "Mark Silver",
    website: "www.argentidesignerjewelers.com/",
    description:
      "Mark Silver strives to create personal connections with customers, but that doesn’t always translate into repeat business. With SpotOn’s integrated solutions, he’s now able to drive repeat visits by offering loyalty rewards and flash discounts during checkout.",
    url: "",
    ctaPrimary: "Read The Story",
    info: [
      {
        numbers: "1400+",
        numberType: "loyalty customers",
      },
      {
        numbers: "10",
        numberType: "marketing emails per month",
      },
    ],
  },
];

export const activmealsSlides = [
  {
    image: "Loyalty product page-1.png",
    companyName: "Activmeals",
    companyIs: "Prepared meals",
    companyLogo: "Loyalty product page logo-1.png",
    address: "Fresno & Clovis, CA",
    owner: "Amir Sadaghiani",
    website: "https://activmeals.com/",
    description:
      "Starting his own business meant Amir Sadaghiani had to compete with established competitors in the Fresno area. With SpotOn’s digital loyalty program, Sadaghiani is able to attract new customers with enrollment rewards and then easily drive repeat visits.",
    url: "",
    ctaPrimary: "Read The Story",
    info: [
      {
        numbers: "242",
        numberType: "new loyalty customers in first 2 months",
      },
      {
        numbers: "150",
        numberType: "loyalty check-ins per month",
      },
    ],
  },
];

export const cacioSlides = [
  {
    image: "Reserve-1.png",
    companyName: "Cacio",
    companyIs: "Casual dining restaurant",
    companyLogo: "Reserve logo-1.png",
    address: "Sacramento, CA",
    owner: "Katie Kinner & Jonathan Kerksieck",
    website: "https://www.caciosacramento.com/",
    description:
      "Forced to transition entirely to takeout, Katie Kenner saw a flood of guests wanting to order and pick up at the same time. With SpotOn Reserve, she’s able to control orders and takeout slots while easily managing reservations and capacity restraints.",
    url: "",
    ctaPrimary: "Read The Story",
    info: [
      {
        numbers: "100%",
        numberType: "Transition to take-out",
      },
      {
        numbers: "",
        numberType: "Saved on additional staffing costs",
      },
    ],
  },
];

export const billySlides = [
  {
    image: "billyA.png",
    companyName: "Billy Bob’s Boneyard",
    companyIs: "Food trailer",
    companyLogo: "",
    address: "Bellaire, Michigan",
    owner: "Lisa Dallas",
    website: "www.facebook.com/Billy-Bobs-Boneyard",
    description:
      "With owner Lisa Dallas moving her BBQ trailer to the marina at Clam Lake, SpotOn Sidekick’s 4G wireless has made it a snap for customers who pull up in boats to pay by credit card.",
    url: "",
    ctaPrimary: "Read The Story",
    info: [
      {
        numbers: "70%",
        numberType: "orders paid by credit card",
      },
      {
        numbers: "3",
        numberType: "new online ordering channels",
      },
    ],
  },
];

export const basecampSlides = [
  {
    image: "basecampA.png",
    companyName: "Base Camp Pizza Co.",
    companyIs: "Pizzeria",
    companyLogo: "basecamp-logo.png",
    address: "Lake Tahoe California",
    owner: "Ray Villaman",
    website: "www.basecamppizzaco.com",
    logoClassName: "max-w-[60px] max-h-[60px]",
    description:
      "With 125 employees and a dine-in waitlist of over 400 during peak season, Base Camp Pizza Co. owner Ray Villaman turns to SpotOn to manage his reservations and waitlist, improve server efficiency, and streamline online ordering to boost daily sales.",
    url: "/case-studies/base-camp-pizza/",
    ctaTitle: "Read the story",
    info: [
      {
        numbers: "$67,000",
        numberType: "annual loyalty revenue",
      },
      {
        numbers: "20",
        numberType: "Locations",
      },
    ],
  },
];

export const ImagineThatSlides = [
  {
    image: "Imagine_That_Image.jpg",
    companyName: "Imagine That",
    companyIs: "Gift baskets",
    companyLogo: "Imagine_That_Logo.png",
    address: "Tallahassee, Florida",
    owner: "Jenny Wells",
    website: "www.imaginethatgiftbaskets.com",
    logoClassName: "max-w-[60px] max-h-[60px]",
    description:
      "Imagine That features creative gift baskets stocked with thoughtfully curated items chosen personally by owner Jenny Wells. And with SpotOn as her partner, she can display and sell those baskets online, increasing her customer base and sales dramatically.",
    url: "",
    ctaTitle: "Read the story",
    info: [
      {
        numbers: "500%",
        numberType: "increase in online sales",
      },
      {
        numbers: "Increase",
        numberType: "in new customers",
      },
    ],
  },
];

export const CrepeCornerSlides = [
  {
    image: "crepe-corner-image.png",
    companyName: "Crepe Corner",
    companyIs: "Coffee",
    companyLogo: "crepe-corner-logo.png",
    address: "Providence and Cranston, Rhode Island",
    owner: "Natacha Legein",
    website: "www.crepecornerri.com",
    logoClassName: "max-w-[60px] max-h-[60px]",
    description:
      "With 2 popular breakfast cafes in Rhode Island, Chef Natacha Legein increases revenue with SpotOn by enabling customers to order online through Crepe Corner's website or Google and optimizing her menu with reports from the SpotOn dashboard.",
    url: "/case-studies/crepe-corner/",
    ctaTitle: "Read the story",
    info: [
      {
        numbers: "8 – 15%",
        numberType: "increase in monthly sales",
      },
      {
        numbers: "8%",
        numberType: "decrease in labor costs",
      },
    ],
  },
];

export const uniquePlantsAndPalmsSlides = [
  {
    image: "unique-plants-and-palms.png",
    companyName: "Unique Plants and Palms",
    companyIs: "Full-service nursery",
    companyLogo: "unique-plants-and-palms-logo.png",
    address: "Jacksonville, FL",
    owner: "Michelle Grabill",
    website: "www.uniqueplantsandpalms.com",
    logoClassName: "max-w-[60px] max-h-[60px]",
    description:
      "Unique Plants and Palms needed to speed up the checkout process and provide better experiences for their customers. Switching to SpotOn Retail helped owner Michelle Grabill save time on admin, and cut down on costly processing fees.",
    url: "/case-studies/unique-plants-and-palms/",
    ctaTitle: "Read the story",
    info: [
      {
        numbers: "$3,000",
        numberType: "saved in processing fees yearly",
      },
      {
        numbers: "5 – 10 minute",
        numberType: "faster checkout times",
      },
    ],
  },
];

export const momoyaSlides = [
  {
    image: "momoya.png",
    companyName: "Momoya",
    companyIs: "Casual dining",
    companyLogo: "momoya-logo.png",
    address: "New York City, NY",
    owner: "KwangHo Lee",
    website: "www.momoyanyc.com",
    logoClassName: "max-w-[60px] max-h-[60px]",
    description:
      "New York's top sushi restaurant, Momoya needed to streamline operations, simplify the payment process, integrate with delivery apps, and get remote access to reports. With SpotOn's cloud-based platform, owner KwangHo Lee has seen the impact on his daily orders, tip percentages, and labor cost.",
    url: "/case-studies/momoya/",
    ctaTitle: "Read the story",
    info: [
      {
        numbers: "10%",
        numberType: "increase in daily orders",
      },
      {
        numbers: "4%",
        numberType: "increase in average tips",
      },
    ],
  },
];

export const blueBarnSlides = [
  {
    image: "blue-barn.png",
    companyName: "Blue Barn",
    companyIs: "Fast casual",
    companyLogo: "blue-barn-logo.png",
    address: "San Francisco, CA",
    owner: "Stryker Scales and Sam Josi",
    website: "www.bluebarngourmet.com",
    logoClassName: "max-w-[60px] max-h-[60px]",
    description:
      "Partners Stryker Scales and Sam Josi found that legacy POS systems couldn’t keep pace when they switched their “slow food” restaurant concepts to take-out only. With SpotOn Order, they were able to streamline their off-premise ordering process, helping increase revenue and more.",
    url: "/case-studies/blue-barn/",
    ctaTitle: "Read the story",
    info: [
      {
        numbers: "11%",
        numberType: "increase in ordering",
      },
      {
        numbers: "$3,600+",
        numberType: "saved monthly in labor costs",
      },
    ],
  },
];

export const deLovelysDeliciousSlides = [
  {
    image: "delovelys-delicious.png",
    companyName: "DeLovely's Delicious Mini Donuts",
    companyIs: "Food truck",
    companyLogo: "delovelys-delicious-logo.png",
    address: "Crawfordsville, IA",
    owner: "Dave and Sherri DeLovely",
    website: "www.delovelys-delicious-mini-donuts.business.site",
    logoClassName: "max-w-[60px] max-h-[60px]",
    description:
      "DeLovely’s Delicious Mini Donuts needed a reliable and user-friendly point-of-sale system for their on-the-go business while also accepting various digital payments without incurring hefty processing fees. SpotOn Sidekick enabled their customers to pick their favorite payment method and allows them to update pricing and menu items, from any device.",
    url: "/case-studies/delovelys-delicious-mini-donuts/",
    ctaTitle: "Read the story",
    info: [
      {
        numbers: "20%",
        numberType: "increase in sales",
      },
      {
        numbers: "12.5%",
        numberType: "increase in customer retention",
      },
    ],
  },
];

export const bardoAndVanaSlides = [
  {
    image: "sante.png",
    companyName: "Sante",
    companyIs: "Fine dining",
    companyLogo: "sante-logo.png",
    address: "Charlotte, NC",
    owner: "Adam Reed",
    website: "",
    logoClassName: "max-w-[60px] max-h-[60px]",
    description:
      "“With SpotOn Reserve, there are no slow nights any more. We used to hope to get to half capacity. Now we know we’re going to do 60% capacity every week night and the weekenzds are always busy.”",
    url: "/case-studies/sante/",
    ctaTitle: "Read the story",
    info: [
      {
        numbers: "40%",
        numberType: "Increase in reservations with online booking",
      },
      {
        numbers: "25%",
        numberType: "Increase in yearly revenue",
      },
    ],
  },
];

export const barbwireBarbecueSlides = [
  {
    image: "barbwire-barbecue.png",
    companyName: "Barbwire Barbecue",
    companyIs: "Quick service",
    companyLogo: "barbwire-barbecue-logo.png",
    address: "Eudora, KS",
    owner: "Jay and Jason Musick",
    website: "www.barbwirebarbecue.com",
    logoClassName: "max-w-[60px] max-h-[60px]",
    description:
      "In September 2020, as Barbwire Barbecue was transforming from a food truck to brick and mortar, owners Jay and Jason Musick realized they needed more than a POS. SpotOn helped them develop a streamlined order process, on-premise and online, while maximizing profits with an easy-to-use loyalty program.",
    url: "/case-studies/barbwire-barbecue/",
    ctaTitle: "Read the story",
    info: [
      {
        numbers: "40%",
        numberType: "increase in revenue",
      },
      {
        numbers: "5 – 10",
        numberType: "hours saved on admin every week",
      },
    ],
  },
];

export const tonysPizzeriaSlides = [
  {
    image: "tonys-pizzeria.png",
    companyName: "Tony’s Pizzeria",
    companyIs: "Casual dining",
    companyLogo: "tonys-pizzeria-logo.png",
    address: "Fort Myers, Florida",
    owner: "Chuck Conrose",
    website: "www.tonyspizzafortmyers.com",
    logoClassName: "max-w-[60px] max-h-[60px]",
    description:
      "“Customers can come into the restaurant, scan a QR code, order, and pay for their food using their phones. That's a huge help to my staff. That's an extra minute a server can spend at another table explaining the menu or cleaning a table. It’s beneficial for us.”",
    url: "/case-studies/tonys-pizza/",
    ctaTitle: "Read the story",
    info: [
      {
        numbers: "$40k",
        numberType: "Increase in monthly sales",
      },
      {
        numbers: "$180k",
        numberType: "in online orders over 4 months",
      },
    ],
  },
];
