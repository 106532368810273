// eslint-disable-next-line import/prefer-default-export
export const onlineOrderingHero = {
  title: "Take profit back with a restaurant online ordering & delivery system",
  subtext:
    "Go commission free. Skip the third-party apps and let your customers order take-out and delivery directly from you with a seamless digital experience that saves everyone time and money.",
  className: "mb-32",
  heroImg: "striker-circle.png",
  imageClassName:
    "w-0 md:w-[70%] xl:w-[80%] xl:translate-x-[-32px] xl:translate-y-[-50px]",
  PrimaryCta: {
    ctaTitle: "Learn more",
    ctaTarget: "/products/online-order-and-delivery/demo/",
  },
  video: {
    videoBtn: "Watch video",
    posterWrapperClass: "",
    posterImg: "striker-poster.png",
    videoUrlId: "OQL1DMctzmM",
  },
  fatTitle: "Online",
};

export const onlineOrderingTrusted = [
  {
    title: "70%",
    subtext: "Guests prefer to order directly from a restaurant",
  },
  {
    title: "67%",
    subtext: "Online ordering customers visit more often",
  },
  {
    title: "18%",
    subtext: "Increase in average ticket with online ordering",
  },
  {
    title: "$7,500+",
    subtext: "Monthly savings compared to third-party delivery apps",
  },
];

export const onlineOrderingWhiteGlove = {
  mainTitle: "The help you need, every step of the way",
  title: "We’re in your corner",
  content:
    "We’ll work closely to create your online menu and configure it to send orders directly to your point-of-sale, while creating a seamless pickup and delivery experience for your guests.",
  ctaInfoPrimary: {
    ctaTitle: "Learn more",
    ctaTarget: "/products/online-order-and-delivery/demo/",
  },
};

export const onlineOrderingLargeFeatures = {
  sectionTitle: "Save money, simplify operations, and own your data",
  featureBlocks: [
    {
      blockTitle: "One solution for take-out, delivery, and more",
      blockImg: "olo-a.png",
      blockSubTitle:
        "End the hassle of dealing with expensive third-party delivery services and entering orders manually into your POS. We make it easy for your guests to order directly from your website, Facebook page, and Google. With direct POS integration and pacing controls for online orders, you can boost sales and kitchen efficiency. Better yet, you keep your guest data so you can easily retarget guests to drive repeat visits.",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/products/online-order-and-delivery/demo/",
      },
    },
    {
      blockTitle: "No-hassle local delivery",
      blockImg: "olo-b.png",
      blockSubTitle:
        "Protect your delivery profit margins without having to hire and manage your own drivers. With SpotOn Delivery, you can leverage the DoorDash delivery network at a fraction of the cost while still owning your guest data.",
      blockList: [
        "Guests order directly from your website",
        "Flat rate fee* with the ability to pass costs on to guests",
        "DoorDash handles delivery logistics",
        "You keep your guest data to retarget with built-in marketing tools",
      ],
      appendix: `*$7.95 per delivery; $9.95 per delivery in California`,
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/products/online-order-and-delivery/demo/",
      },
    },
    {
      blockTitle: "Let guests order & pay from the table",
      blockImg: "online-ordering-let- guests-order.png",
      blockSubTitle:
        "Free up your staff to focus on the guest experience with SpotOn QR. Choose your guest’s QR dining experience with the option for guests to order & pay from the table, or scan a code on their receipt for contactless payment. With group ordering, it's easy for your staff and guests to order from their own devices and split the check by amount. We’ll set it up to work your way, so you can turn tables faster, increase average ticket sizes, and increase average tips for your staff.",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/products/online-order-and-delivery/demo/",
      },
    },
    {
      blockTitle: "Reach more hungry guests with Order with Google",
      blockImg: "order-google.png",
      blockSubTitle:
        "SpotOn makes it easier than ever for your guests to find and order from your restaurant’s Business Profile on Google. SpotOn’s online ordering solution, SpotOn Order is now integrated with Order with Google. Your guests search for what they’re craving on Google, find your restaurant and order directly from your Business Profile on Google with ease. No apps. No logins. Easy.",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/products/online-order-and-delivery/demo/",
      },
    },
    {
      blockTitle: "Boost revenue with e-gift cards",
      blockImg: "e-gift-cards.png",
      blockSubTitle:
        "Give your customers another way to support your business while attracting new customers and boosting revenue. Studies show that 65% of gift card holders spend an extra 38% beyond the value on their gift card.",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/products/online-order-and-delivery/demo/",
      },
    },
  ],
};

export const onlineOrderingBusinessTypesCar = {
  title: "The right technology for restaurants big and small",
  subtext: "A seamless digital experience, whether you're serving 50 or 500.",
  slides: [
    {
      bg: "quick-dining.png",
      cardTitle: "Quick service restaurant",
      cardContent: "Work faster and smarter",
      icon: "qsr.png",
      linkTo: "/quick-service-pos",
    },
    {
      bg: "casual-dining.png",
      cardTitle: "Casual dining",
      cardContent: "Unlock your restaurant’s potential",
      icon: "casual-dining.png",
      linkTo: "/casual-dining-pos",
    },
    {
      bg: "fine-dining.png",
      cardTitle: "Fine Dining",
      cardContent: "Tailor-made restaurant solutions",
      icon: "fine-dining.png",
      linkTo: "/fine-dining-pos",
    },
  ],
};

export const onlineOrderingVideo = {
  title: "Keep your guests fed and revenue rolling",
  videoBtn: "Watch video",
  posterImg: "olo-poster.png",
  videoUrlId: "NuZ1GIh9rjE",
};

export const onlineOrderingSolutionsSection = {
  title: "Part of an end-to-end solution to run your restaurant",
  subText: `Combine online ordering from SpotOn with our other powerful restaurant tools that are tailor-made to help you boost efficiency, grow sales, and create a better guest experience.`,
  SecondaryCta: {
    ctaTitle: "View all",
    ctaTarget: "/products/online-order-and-delivery/demo/",
  },
};

export const onlineOrderingTestimonials = {
  title: "What other business owners are saying",
  reviews: [
    {
      quote:
        "From the initial consultation, to ordering, to implementation and integration, the entire process of on-boarding the SpotOn POS System has been seamless and has exceeded our expectations.",
      reviewDate: "Mar 9, 2021",
      reviewer: "Pete M.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn continues to be an invaluable technology partner that listens well and proactively presents innovative solutions to drive our business forward.",
      reviewDate: "Feb 9, 2021",
      reviewer: "Ben P.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn has given me the data access I need to manage my business from a macro as well as micro perspective. The software is easy to access and utilize.",
      reviewDate: "Dec 5, 2020",
      reviewer: "Pamela H.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "Every issue and question has been answered or addressed within hours, if not minutes.I can't say enough good about this company and software.",
      reviewDate: "Feb 24, 2021",
      reviewer: "Brandy R.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "From back office, digital marketing, built our website to take orders off of. The programming is great to allow you to do more tasks with better trained staff.",
      reviewDate: "Dec 1, 2020",
      reviewer: "Marcus O.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
  ],
};

export const onlineOrderingIntegrations = {
  title: "Integrations",
  subtext:
    "Sync all kind of third party applications and service with SpotOn and automate your revenue growth, retention and onlineOrdering processes.",
  integrationsContent: [
    {
      title: "Compatible with SpotOn Register.",
      subtext:
        "Run and grow your business more efficiently than ever before with our cutting-edge point-of-sale solution.",
      contentImg: "register.png",
      ImgClassName: "register",
      btnInfo: {
        ctaTitle: "How it Works",
        ctaTarget: "/products/online-order-and-delivery/demo/",
      },
    },
    {
      title: "Out of the box Stripe integration.",
      subtext:
        "Accept onlineOrderings and move money globally with Stripe’s powerful APIs and software solutions",
      contentImg: "phone.png",
      ImgClassName: "phone",
      btnInfo: {
        ctaTitle: "Read More",
        ctaTarget: "/products/online-order-and-delivery/demo/",
      },
    },
  ],
};

export const onlineOrderingCTA = {
  title: "Book your consultation.",
  ctaImg: "cta-people.png",
  ctaInfo: {
    ctaTitle: "Learn more",
    ctaTarget: "/products/online-order-and-delivery/demo/",
  },
};

export const onlineOrderingFaqs = {
  title: "Frequently Asked Questions",
  ctaInfo: {
    ctaTitle: "View more",
    ctaTarget: "/faq",
  },
  list: [
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
  ],
};

export const oloSolutionsSlides = [
  {
    title: "Restaurants",
    img: "Restaurant.png",
    link: "/restaurant-pos",
  },
  {
    title: "Payments",
    link: "/products/payments",
    img: "Payments.png",
  },
  {
    title: "Delivery",
    link: "/products/delivery",
    img: "Delivery.png",
  },
  {
    title: "Loyalty",
    link: "/products/loyalty",
    img: "Loyalty.png",
  },
  {
    title: "Reserve",
    link: "/products/reserve",
    img: "Reserve.png",
  },
  {
    title: "Website",
    link: "/products/website",
    img: "E-commerce & websites.png",
  },
];
