import React from "react";
import loadable from "@loadable/component";
import Layout from "../../../components/Layout/layout";
import SEO from "../../../components/seo";
import {
  onlineOrderingHero,
  onlineOrderingTrusted,
  onlineOrderingWhiteGlove,
  onlineOrderingLargeFeatures,
  onlineOrderingBusinessTypesCar,
  onlineOrderingVideo,
  onlineOrderingSolutionsSection,
  onlineOrderingTestimonials,
  onlineOrderingCTA,
  oloSolutionsSlides,
  // onlineOrderingFaqs,
} from "../../../data/product-pages/online-ordering-data";
import Hero from "../../../components/Hero/hero";

import {
  blueCrabSlides,
  heroSuccess,
} from "../../../data/success-stories-data";
import ogImage from "../../../images/global_assets/og-image.png";

const TrustedNumbers = loadable(() =>
  import("../../../components/TrustedNumbers/TrustedNumbers")
);
const WhiteGlove = loadable(() =>
  import("../../../components/WhiteGlove/WhiteGlove")
);
const LargeFeatures = loadable(() =>
  import("../../../components/LargeFeatures/LargeFeatures")
);
const BusinessTypes = loadable(() =>
  import("../../../components/BusinessTypes/BusinessTypes")
);
const VideoSection = loadable(() =>
  import("../../../components/VideoSection/VideoSection")
);
const PartnerLogos = loadable(() =>
  import("../../../components/PartnerLogos/PartnerLogos")
);
const TestmonialReviews = loadable(() =>
  import("../../../components/TestimonialReviews/TestmonialReviews")
);
const LargeCta = loadable(() => import("../../../components/CTAs/LargeCta"));
const ProductLines = loadable(() =>
  import("../../../components/ProductLines/ProductLines")
);
const SuccessStories = loadable(() =>
  import("../../../components/SuccessStories/SuccessStories")
);

const whiteGloveImport = "terminal-pp-white-glove.png";

const onlineOrdering = () => {
  return (
    <Layout>
      <SEO
        title="Restaurant Online Ordering & Delivery System | SpotOn POS"
        description="SpotOn is the best online ordering app, software & point of sale system for restaurants. We are the reliable POS solution for your guests and staff."
        image={`https://spoton.com/${ogImage}`}
      />
      <Hero
        sectionData={onlineOrderingHero}
        className="mb-[332px] md:mb-0"
        circleBg={false}
      />
      <TrustedNumbers numbersArray={onlineOrderingTrusted} />
      <WhiteGlove
        sectionData={onlineOrderingWhiteGlove}
        whiteGloveBg={whiteGloveImport}
      />
      <LargeFeatures
        sectionData={onlineOrderingLargeFeatures}
        style={{ marginTop: "128px" }}
      />
      <BusinessTypes sectionData={onlineOrderingBusinessTypesCar} />
      <SuccessStories sectionData={heroSuccess} slides={blueCrabSlides} />
      {/* <VideoSection sectionData={onlineOrderingVideo} /> */}
      <PartnerLogos />
      <ProductLines
        sectionData={onlineOrderingSolutionsSection}
        productSlides={oloSolutionsSlides}
      />
      <TestmonialReviews sectionData={onlineOrderingTestimonials} />
      <PartnerLogos />
      <LargeCta sectionData={onlineOrderingCTA} />
      {/* <Faqs sectionData={onlineOrderingFaqs} /> */}
    </Layout>
  );
};

export default onlineOrdering;
